<template>
  <!--begin::Project Listing-->
  <div class="project-template" v-if="getPermission('documents:view')">
    <PageHeaderCount
      moduleType="document"
      :dataLoading.sync="dataLoading"
      :statusList.sync="statusList"
      allkey="all_documents"
      countkey="documents_status_count"
    ></PageHeaderCount>
    <ListingTemplate :customClass="'project-listing'">
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template>
              <v-col cols="6" class="pt-0">
                <h1 class="form-title d-flex margin-auto">
                  <v-select
                    :disabled="dataLoading"
                    :items="statusList"
                    v-model="status"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="item.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          <template v-if="item.value == 'all'">{{
                            item.all_documents
                          }}</template>
                          <template v-else>{{
                            item.documents_status_count
                          }}</template>
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select>
                </h1>
              </v-col>
              <v-col cols="6" class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('documents:create')">
                  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button"
                    v-on:click="importDialog = true"
                    color="cyan white--text"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Upload
                  </v-btn>
                </template>

                <v-menu v-if="false" content-class="custom-menu-list" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading || exportLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                      :loading="exportLoading"
                    >
                      <v-icon dark>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                </v-menu>
                <v-menu
                  max-height="400"
                  max-width="250"
                  offset-y
                  :close-on-content-click="false"
                  content-class="white-background"
                  v-if="type != 'project'"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button btn-tab-hide"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="cols.fixed || dataLoading"
                          color="cyan"
                          hide-details
                          class="mt-0 mb-0"
                        ></v-checkbox>
                        <v-icon class="draggable-drag-icon" right color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
                <PageTips module="documents"></PageTips>
              </v-col>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Documents"
          :basicSearchFields="['name', 'document tags']"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <!-- <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 60vh; position: relative"
        > -->
        <ListingTable
          :columnCount.sync="defaultColDefs.length"
          :dataLoading.sync="dataLoading"
          :rowData.sync="rowData"
        >
          <template v-slot:thead>
            <thead>
              <tr>
                <template v-for="cols in defaultColDefs">
                  <th
                    v-if="cols.visible"
                    :key="cols.id"
                    class="simple-table-th"
                    :class="{
                      'checkbox-width-limit': cols.checkbox,
                      'pointer-events-none': lodash.isEmpty(rowData),
                    }"
                  >
                    <template v-if="cols.checkbox">
                      <v-checkbox
                        dense
                        :disabled="dataLoading"
                        v-model="selectedAll"
                        color="cyan"
                        class="hide-margin"
                        hide-details
                      ></v-checkbox>
                    </template>
                    <template v-else>
                      <div
                        v-if="cols.sortable"
                        class="simple-table-text sortable"
                        v-on:click="
                          !dataLoading
                            ? updateSorting({
                                field: cols.field,
                                sort: cols.sort,
                              })
                            : 'javascript:void(0)'
                        "
                      >
                        {{ cols.headerName }}
                      </div>
                      <div v-else class="simple-table-text">
                        {{ cols.headerName }}
                      </div>
                      <template v-if="cols.sort">
                        <v-icon
                          class="simple-table-sort"
                          v-if="cols.sort == 'asc'"
                          small
                          right
                          color="cyan"
                          >mdi-sort-ascending</v-icon
                        >
                        <v-icon
                          class="simple-table-sort"
                          v-if="cols.sort == 'desc'"
                          small
                          right
                          color="cyan"
                          >mdi-sort-descending</v-icon
                        >
                      </template>
                    </template>
                  </th>
                </template>
              </tr>
            </thead>
          </template>
          <template v-slot:tbody>
            <tbody class="custom-border-bottom">
              <template v-if="!lodash.isEmpty(rowData)">
                <tr v-for="(data, index) in rowData" :key="index" link>
                  <template v-for="cols in defaultColDefs">
                    <td
                      v-if="cols.visible"
                      :key="cols.id"
                      :class="{
                        'simple-table-td': !cols.checkbox,
                        'checkbox-width-limit': cols.checkbox,
                      }"
                    >
                      <template v-if="cols.checkbox">
                        <v-checkbox
                          dense
                          v-model="selectedRows"
                          v-bind:value="data.id"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else-if="cols.field == 'name'">
                        <v-btn
                          class="mx-2 custom-bold-button"
                          color="cyan"
                          outlined
                          small
                          :loading="isDownloading(index)"
                          :disabled="primaryLoader || isDownloading(index)"
                          icon
                          v-on:click="
                            downloadAPIFile(data.id, data.file.name, index)
                          "
                          ><v-icon>mdi-download</v-icon>
                        </v-btn>
                        {{ data.name }}
                        <div
                          v-if="isExpired(data)"
                          class="project-listing-status"
                        >
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                v-bind="attrs"
                                v-on="on"
                                class="custom-status d-inline font-weight-600 custom-grey-border text-uppercase justify-center"
                                color="cyan"
                                text-color="white"
                                label
                                small
                              >
                                Expired
                              </v-chip>
                            </template>
                            <span>Status</span>
                          </v-tooltip>
                        </div>
                      </template>
                      <template v-else-if="cols.field == 'details'">
                        <p class="m-0 custom-nowrap-ellipsis">
                          <b>Start Date: </b>
                          <template v-if="data.start_date">{{
                            formatDate(data.start_date)
                          }}</template>
                        </p>
                        <p class="m-0 custom-nowrap-ellipsis">
                          <b>Expiry Date: </b>
                          <template v-if="data.expiry_date">{{
                            formatDate(data.expiry_date)
                          }}</template>
                        </p>
                      </template>
                      <template v-else-if="cols.field == 'reminder_details'">
                        <template v-if="data.reminder_type">
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Reminder Type: </b>
                            <template v-if="data.reminder_type">{{
                              data.reminder_type
                            }}</template>
                          </p>
                          <p
                            v-if="data.reminder_date"
                            class="m-0 custom-nowrap-ellipsis"
                          >
                            <b>Date: </b>
                            <template v-if="data.reminder_date">{{
                              formatDate(data.reminder_date)
                            }}</template>
                          </p>
                          <p
                            v-if="data.reminder_frequency"
                            class="m-0 custom-nowrap-ellipsis"
                          >
                            <b>Frequency: </b>
                            <template v-if="data.reminder_frequency">{{
                              data.reminder_frequency
                            }}</template>
                          </p>
                          <p
                            v-if="data.reminder_start_date"
                            class="m-0 custom-nowrap-ellipsis"
                          >
                            <b>Start Date: </b>
                            <template v-if="data.reminder_start_date">{{
                              formatDate(data.reminder_start_date)
                            }}</template>
                          </p>
                          <p
                            v-if="data.reminder_end_date"
                            class="m-0 custom-nowrap-ellipsis"
                          >
                            <b>End Date: </b>
                            <template v-if="data.reminder_end_date">{{
                              formatDate(data.reminder_end_date)
                            }}</template>
                          </p>
                        </template>
                      </template>

                      <template v-else-if="cols.field == 'tags'">
                        <div v-for="tag in data.tags" :key="tag">
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                v-bind="attrs"
                                v-on="on"
                                class="custom-status d-inline font-weight-600 custom-grey-border text-uppercase justify-center"
                                color="cyan"
                                text-color="white"
                                label
                                small
                              >
                                {{ tag }}
                              </v-chip>
                            </template>
                            <span>Tag</span>
                          </v-tooltip>
                        </div>
                      </template>
                      <template v-else-if="cols.field == 'owner'">
                        <div class="project-listing-project">
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Name: </b>
                            {{ data.added_by && data.added_by.display_name }}
                          </p>
                        </div>
                      </template>

                      <template v-else-if="cols.field == 'tags'">
                        <div v-for="tag in data.tags" :key="tag">
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                v-bind="attrs"
                                v-on="on"
                                class="custom-status d-inline font-weight-600 custom-grey-border text-uppercase justify-center"
                                color="cyan"
                                text-color="white"
                                label
                                small
                              >
                                {{ tag }}
                              </v-chip>
                            </template>
                            <span>Tag</span>
                          </v-tooltip>
                        </div>
                      </template>

                      <template v-else-if="cols.field == 'modified'">
                        <TableActivity
                          v-if="!lodash.isEmpty(data.added_by)"
                          :data.sync="data"
                        >
                          <template v-slot:display_name>
                            {{ data.added_by.display_name }}
                          </template>
                          <template v-slot:date_time>
                            {{ data.modified_at }}
                          </template>
                          <template v-slot:format_date_time>
                            {{ formatDateTime(data.updated_at) }}
                          </template>
                        </TableActivity>
                      </template>
                      <template v-else>
                        <div v-html="printRowData(cols, data)"></div>
                      </template>
                    </td>
                  </template>
                </tr>
              </template>
              <tr v-else-if="!dataLoading">
                <td :colspan="defaultColDefs.length" class="py-4">
                  <p
                    class="m-0 row-not-found text-center font-weight-500 font-size-16"
                  >
                    <img
                      :src="$assetURL('media/error/empty.png')"
                      class="row-not-found-image"
                    />
                    Uhh... There are no Document at the moment.
                  </p>
                </td>
              </tr>
            </tbody>
          </template>
        </ListingTable>
        <!-- </perfect-scrollbar> -->
        <ListingFooter
          :dataLoading.sync="dataLoading"
          :showingFrom.sync="showingFrom"
          :showingTo.sync="showingTo"
          :totalRows.sync="totalRows"
          :currentPage.sync="currentPage"
          :totalPages.sync="totalPages"
        ></ListingFooter>
        <Dialog :commonDialog="searchDialog">
          <template v-slot:title>Filter Documents</template>
          <template v-slot:body>
            <!-- <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="max-height: 60vh; position: relative"
            > -->
            <v-container fluid class="pt-0 custom-search-filter">
              <v-row>
                <template v-for="(field, index) in searchableArray">
                  <v-col md="6" :key="index" v-if="field != 'dates'">
                    <v-text-field
                      v-model.trim="listingSearch[field]"
                      dense
                      filled
                      hide-details
                      :label="getFilterLabel(field, 'Document')"
                      solo
                      flat
                      clearable
                      @keydown.enter="filterRows"
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                </template>
              </v-row>
            </v-container>
            <!-- </perfect-scrollbar> -->
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="filterRows"
            >
              Filter
            </v-btn>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="searchDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>

        <template v-if="getPermission('project:create')">
          <Dialog :commonDialog="importDialog">
            <template v-slot:title>Attach Files</template>
            <template v-slot:body>
              <!-- <perfect-scrollbar
                :options="{ suppressScrollX: true }"
                class="scroll custom-box-top-inner-shadow"
                style="max-height: 60vh; position: relative"
              > -->
              <v-form
                ref="documentImport"
                v-model="valid_import"
                lazy-validation
                v-on:submit.stop.prevent="importRows()"
              >
                <v-container fluid class="pt-0 custom-search-filter">
                  <div class="mt-4" v-if="getPermission('attachment:create')">
                    <label class="font-weight-700 font-size-16"
                      >Attachments</label
                    >
                    <div class="text-center">
                      <FileTemplateSingle
                        :attachments.sync="attachment"
                        v-on:file:updated="updateFiles"
                        allowUpload
                      ></FileTemplateSingle>
                    </div>
                  </div>
                  <br />

                  <div>
                    <label class="font-weight-700 font-size-16">Name</label>
                    <v-text-field
                      v-model.trim="uploadFile.name"
                      dense
                      filled
                      color="cyan"
                      label="Name"
                      solo
                      flat
                      :rules="[
                        validateRules.required(uploadFile.name, 'Name'),
                        validateRules.minLength(uploadFile.name, 'Name', 1),
                        validateRules.maxLength(uploadFile.name, 'Name', 100),
                      ]"
                    ></v-text-field>
                  </div>
                  <div>
                    <label class="font-weight-700 font-size-16">Project</label>
                    <v-autocomplete
                      dense
                      color="cyan"
                      filled
                      :items="projectList"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model.trim="uploadFile.project"
                      label="Project"
                      solo
                      flat
                      item-color="cyan"
                      item-text="name"
                      item-value="id"
                      hide-details
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-capitalize font-weight-500 font-size-16"
                            >#{{ item.barcode }} -
                            {{ item.name }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </template>
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Project Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </div>
                  <br />
                  <div>
                    <label class="font-weight-700 font-size-16"
                      >Document Tags</label
                    >
                    <v-autocomplete
                      dense
                      color="cyan"
                      filled
                      :items="tagList"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model.trim="uploadFile.tags"
                      label="Document Tags"
                      solo
                      flat
                      item-color="cyan"
                      item-text="text"
                      item-value="text"
                      hide-details
                      multiple
                      append-outer-icon="mdi-cog"
                      v-on:click:append-outer="manageTagsDialog = true"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.text }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text font-size-12"
                        >
                          (+{{ uploadFile.tags.length - 1 }} others)
                        </span>
                      </template>
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Tag Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </div>
                  <br />
                  <div>
                    <label class="font-weight-700 font-size-16"
                      >Start Date</label
                    >
                    <div>
                      <v-menu
                        v-model="startDatePicker"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            filled
                            flat
                            label="Start Date"
                            hide-details
                            solo
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :value="formattedStartDate"
                            color="cyan"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model.trim="uploadFile.start_date"
                          color="cyan"
                          v-on:input="startDatePicker = false"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </div>
                  <br />
                  <div>
                    <label class="font-weight-700 font-size-16"
                      >Expiry Date</label
                    >
                    <div>
                      <v-menu
                        v-model="endDatePicker"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            filled
                            flat
                            label="Expiry Date"
                            hide-details
                            solo
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :value="formattedEndDate"
                            color="cyan"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model.trim="uploadFile.end_date"
                          color="cyan"
                          v-on:input="endDatePicker = false"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </div>
                  <div>
                    <v-checkbox
                      v-model.trim="uploadFile.is_public"
                      label="Is Public?"
                      :value="1"
                      hide-details
                      color="cyan"
                      class="mr-4"
                      :disabled="pageLoading"
                    ></v-checkbox>
                    <v-checkbox
                      v-model.trim="uploadFile.set_reminder"
                      label="Set Reminder"
                      :value="1"
                      hide-details
                      color="cyan"
                      class="mr-4"
                      :disabled="pageLoading"
                    ></v-checkbox>
                  </div>
                  <br />
                  <template v-if="uploadFile.set_reminder">
                    <div>
                      <label class="font-weight-700 font-size-16"
                        >Reminder Type</label
                      >
                      <v-autocomplete
                        dense
                        color="cyan"
                        filled
                        :items="reminderTypes"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        v-model.trim="uploadFile.reminder_type"
                        label="Reminder Types"
                        solo
                        flat
                        item-color="cyan"
                        item-text="text"
                        item-value="text"
                        hide-details
                      >
                        <template v-slot:selection="{ item }">
                          {{ item.text }}
                        </template>
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Reminder Type Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </div>
                    <br />
                    <template
                      v-if="uploadFile.reminder_type == 'One Time Reminder'"
                    >
                      <div>
                        <label class="font-weight-700 font-size-16">
                          Date</label
                        >
                        <div>
                          <v-menu
                            v-model="reminderDatePicker"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                dense
                                filled
                                flat
                                label="Date"
                                hide-details
                                solo
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :value="formattedReminderDate"
                                color="cyan"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model.trim="uploadFile.reminder_date"
                              color="cyan"
                              v-on:input="reminderDatePicker = false"
                            ></v-date-picker>
                          </v-menu>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <br />
                      <div>
                        <label class="font-weight-700 font-size-16">
                          Start Date</label
                        >
                        <div>
                          <v-menu
                            v-model="reminderStartDatePicker"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                dense
                                filled
                                flat
                                label="Start Date"
                                hide-details
                                solo
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :value="formattedReminderStartDate"
                                color="cyan"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model.trim="uploadFile.reminder_start_date"
                              color="cyan"
                              v-on:input="reminderStartDatePicker = false"
                            ></v-date-picker>
                          </v-menu>
                        </div>
                      </div>
                      <br />
                      <div>
                        <label class="font-weight-700 font-size-16">
                          Frequency</label
                        >
                        <div>
                          <v-text-field
                            dense
                            color="cyan"
                            filled
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            v-model.trim="uploadFile.frequency"
                            label="Frequency"
                            :rules="[
                              validateRules.minLength(
                                uploadFile.frequency,
                                'Estimated Hours',
                                1
                              ),
                              validateRules.maxLength(
                                uploadFile.frequency,
                                'Estimated Hours',
                                100
                              ),
                            ]"
                            solo
                            flat
                            type="number"
                            min="0"
                            hide-details
                            v-on:keypress="
                              limitDecimal($event, uploadFile.frequency)
                            "
                          ></v-text-field>
                        </div>
                      </div>
                      <br />
                      <div>
                        <label class="font-weight-700 font-size-16">
                          End Date</label
                        >
                        <div>
                          <v-menu
                            v-model="reminderEndDatePicker"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                dense
                                filled
                                flat
                                label="End Date"
                                hide-details
                                solo
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :value="formattedReminderEndDate"
                                color="cyan"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model.trim="uploadFile.reminder_end_date"
                              color="cyan"
                              v-on:input="reminderEndDatePicker = false"
                            ></v-date-picker>
                          </v-menu>
                        </div>
                      </div>
                    </template>
                  </template>
                </v-container>
              </v-form>
              <!-- </perfect-scrollbar> -->
            </template>
            <template v-slot:action>
              <v-btn
                v-if="!excel_imported"
                :disabled="dataLoading || !valid_import || loading_imported"
                class="mx-2 custom-grey-border custom-bold-button"
                color="cyan white--text"
                v-on:click="onSubmit()"
              >
                Upload
              </v-btn>
              <v-btn
                :disabled="loading_imported"
                class="mx-2 custom-grey-border custom-bold-button"
                v-on:click="importDialog = false"
              >
                Close
              </v-btn>
            </template>
          </Dialog>
          <ManageTags
            :commonDialog.sync="manageTagsDialog"
            :tag.sync="tagList"
            v-on:close-dialog="manageTagsDialog = false"
            v-on:get-expense-category="geTags()"
          ></ManageTags>
        </template>
      </template>
    </ListingTemplate>
  </div>
  <!--end::Project Listing-->
</template>

<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PATCH, POST, QUERY } from "@/core/services/store/request.module";

import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";

import JwtService from "@/core/services/jwt.service";
import FileTemplateSingle from "@/view/pages/partials/FileTemplateSingle.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";

import moment from "moment-timezone";
import AppConfiguration from "@/core/config/app.config";
import ManageTags from "@/view/pages/partials/Manage-Tags.vue";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";

export default {
  mixins: [CommonMixin, ListingMixin, ValidationMixin, FileManagerMixin],
  name: "project-list",
  props: {
    detail: {
      type: Object,
      required: false,
      default: () => {
        return new Object();
      },
    },
    type: {
      type: String,
      required: false,
      default: "document",
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.parent = this.lodash.toSafeInteger(param.id);
        if (this.parent > 0) {
          this.uploadFile.project = this.parent;
          //this.getBudget();
        }
      },
    },
  },
  data() {
    return {
      pageLoading: true,
      attachment: new Array(),
      pageTips: false,

      exportLoading: false,
      pageModule: "document-listing",
      routeAPI: "documents",
      routeName: "documents",
      routeDetailName: "admin.documents",
      manageTagsDialog: false,
      status: "all",
      file: "",
      importDialog: false,
      excel_imported: false,
      valid_import: true,
      loading_imported: false,
      error_message: [],
      success_message: [],
      statusList: [],
      tagList: new Array(),
      projectList: new Array(),
      reminderTypes: [
        { text: "One Time Reminder" },
        { text: "Daily Reminder" },
        { text: "Weekly Reminder" },
        { text: "Monthly Reminder" },
        { text: "Quarterly Reminder" },
        { text: "Half Yearly Reminder" },
      ],
      startDatePicker: false,
      endDatePicker: false,
      reminderDatePicker: false,
      reminderStartDatePicker: false,
      reminderEndDatePicker: false,
      uploadFile: {
        file: [
          {
            id: null,
            name: null,
          },
        ],
        name: null,
        project: null,
        tags: [],
        start_date: null,
        end_date: null,
        set_reminder: 0,
        is_public: 0,
        reminder_date: null,
        reminder_type: "One Time Reminder",
        reminder_start_date: null,
        reminder_end_date: null,
        frequency: null,
      },
    };
  },
  components: {
    draggable,

    PageTips,
    PageHeaderCount,
    TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    Dialog,
    FileTemplateSingle,
    ManageTags,
  },
  methods: {
    isExpired(param) {
      if (moment(param.expiry_date).isBefore(moment())) {
        return true;
      }
      return false;
    },
    updateFiles(param) {
      this.uploadFile.file = param;
      this.uploadFile.name = param[0].name;
    },
    downloadSample() {
      const token = JwtService.getToken();
      const url = this.$apiURL + "project/sample-download?token=" + token;
      window.open(url, "_target");
    },
    projectExport() {
      const token = JwtService.getToken();
      const url = this.$apiURL + "project/export?token=" + token;
      window.open(url, "_target");
    },
    handleFileUpload(param) {
      this.file = param;
      this.excel_imported = false;
      this.success_message = [];
      this.error_message = [];
    },

    onSubmit() {
      const _this = this;
      try {
        _this.formLoading = true;

        _this.$store
          .dispatch(POST, {
            url: "document/upload",
            data: _this.uploadFile,
          })
          .then(({ data }) => {
            _this.importDialog = false;
            _this.getRows();
            _this.uploadFile = {
              file: [
                {
                  id: null,
                  name: null,
                },
              ],
              name: null,
              project: null,
              tags: [],
              start_date: null,
              end_date: null,
              set_reminder: 0,
              is_public: 0,
              reminder_date: null,
              reminder_type: "One Time Reminder",
              reminder_start_date: null,
              reminder_end_date: null,
              frequency: null,
            };
            console.log(data);
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      } catch (error) {
        _this.logError(error);
      }
    },

    updateMoreAction(action) {
      const _this = this;
      switch (action) {
        case "mark_as_finish":
          _this.updateProjectStatus(5);
          break;
        case "mark_as_cancel":
          _this.updateProjectStatus(4);
          break;
        case "mark_as_hold":
          _this.updateProjectStatus(3);
          break;
        case "mark_as_inprogress":
          _this.updateProjectStatus(2);
          break;
      }
    },
    updateProjectStatus(status) {
      const _this = this;
      _this.dataLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "project/status",
          data: { project: _this.selectedRows, status },
        })
        .then(() => {
          _this.getRows();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.dataLoading = false;
        });
    },
    selectCustomer(customer) {
      if (customer > 0) {
        this.$router.push(
          this.getDefaultRoute("project.create", {
            query: { customer },
          })
        );
      }
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          _this.importDialog = true;
          break;
        case "export_items":
          _this.projectExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("project");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    },

    geTags() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "project/tags",
          data: { customer: _this.customer },
        })
        .then(({ data }) => {
          _this.tagList = data;
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getProjects() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "document/projects",
        })
        .then(({ data }) => {
          _this.projectList = data;
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  computed: {
    formattedReminderStartDate() {
      let Config = AppConfiguration.get();
      if (Config && this.uploadFile.reminder_start_date) {
        return moment(this.uploadFile.reminder_start_date).format(
          Config.dateFormat
        );
      }
      return this.uploadFile.reminder_start_date;
    },
    formattedReminderEndDate() {
      let Config = AppConfiguration.get();
      if (Config && this.uploadFile.reminder_end_date) {
        return moment(this.uploadFile.reminder_end_date).format(
          Config.dateFormat
        );
      }
      return this.uploadFile.reminder_end_date;
    },
    formattedReminderDate() {
      let Config = AppConfiguration.get();
      if (Config && this.uploadFile.reminder_date) {
        return moment(this.uploadFile.reminder_date).format(Config.dateFormat);
      }
      return this.uploadFile.reminder_date;
    },
    formattedStartDate() {
      if (
        this.uploadFile.end_date &&
        moment(this.uploadFile.start_date).isAfter(this.uploadFile.end_date)
      ) {
        return null;
      }
      let Config = AppConfiguration.get();
      if (Config && this.uploadFile.start_date) {
        return moment(this.uploadFile.start_date).format(Config.dateFormat);
      }
      return this.uploadFile.start_date;
    },
    formattedEndDate() {
      if (
        this.uploadFile.start_date &&
        moment(this.uploadFile.end_date).isBefore(this.uploadFile.start_date)
      ) {
        return null;
      }
      let Config = AppConfiguration.get();
      if (Config && this.uploadFile.end_date) {
        return moment(this.uploadFile.end_date).format(Config.dateFormat);
      }
      return this.uploadFile.end_date;
    },
  },
  mounted() {
    this.geTags();
    this.getProjects();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Documents",
      },
    ]);
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.defaultColDefs = [
      /* {
        headerName: "",
        field: "id",
        sort: null,
        visible: true,
        fixed: true,
        sortable: false,
        checkbox: true,
        order: 1,
      }, */
      {
        headerName: "Name ",
        field: "name",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 2,
      },
      {
        headerName: "Document Tags",
        field: "tags",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 2,
      },
      {
        headerName: "Dates",
        field: "details",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 2,
      },
      {
        headerName: "Reminder",
        field: "reminder_details",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 2,
      },
      /* {
        headerName: "Owner",
        field: "owner",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 3,
      }, */
      {
        headerName: "Modified",
        field: "modified",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 4,
      },
      /* {
        headerName: "File Size",
        field: "file_size",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 4,
      }, */
    ];

    _this.defaultColShow = _this.defaultColDefs.map((col) => col.field);

    _this.status = _this.$route.query.status || _this.status;

    _this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 10;
  },
};
</script>
